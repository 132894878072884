import styles from './styles.module.css'
import { createContext, useEffect, useMemo, useState } from 'react'
import NavDrawer from '../nav-drawer/NavDrawer'
import linksData from '../../../global/tsx/links'
import NavLinkPropsWithElement from '../../../global/types/NavLinkPropsWithElement'
import { RequireAuth } from '../../../global/components/auth/RequireAuth'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import ModalContent from '../../../work-orders/components/work-order-detail/components/main/ModalContent'
import { Login } from '../../../login/Login'
import CreditCardUpdateMain from '../../../customer-update-cc-page/components/CreditCardUpdateMain'
import TipsPage from '../../../tips-page/components/TipsPage'
import CustomerSchedulerMain from '../../../customer-scheduler/components/CustomerSchedulerMain'
import JmulletTools from '../../../jmullet-tools/components/JmulletTools'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchData } from '../../../global/utils/fetch'
import { getUserPermissions } from './api'
import { IUser } from '../../../../../app/entities/User'
import QueuesMain from '../../../queues/components/main/QueuesMain'
import { MODES } from '../../../global/constants/scheduler'
import OrderTracker from '../../../order-tracker/components/main/OrderTracker'
import { IRole } from '../../../../../app/entities/Role'
import UserManagementMain from '../../../user-management/main/UserManagementMain'
import hasPermission from '../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../global/constants/permissions'
import { VanContextProvider } from '../../../global/context/van-context/VanContext'
import LogRocket from 'logrocket'

interface IUserContext {
  user: IUser | null
  refetchUser: () => void
}

export const UserContext = createContext<IUserContext>({
  user: null,
  refetchUser: () => {},
})

function BaseContainer() {
  const navigate = useNavigate()
  const { user } = useAuthenticator((context) => [context.user])
  const [userRole, setUserRole] = useState<IRole>({} as IRole)
  const [userData, setUserData] = useState<IUser | null>(null)

  const links: Array<NavLinkPropsWithElement> = useMemo(
    () =>
      linksData.filter((elem) => {
        const { permissionInfo } = elem
        const { category, name } = permissionInfo || {}

        const allowPermissionFound =
          !category ||
          !name ||
          hasPermission({
            role: { permissions: userRole.permissions || [] },
          } as IUser).allow(category, name)

        return allowPermissionFound
      }),
    [userRole.permissions],
  )

  const { pathname } = useLocation()
  const routesNotDisplayingNavDrawer = [
    /^\/customer-tip-screen\/.+$/,
    /^\/customer-update-cc\/.+$/,
    /^\/login(\/.*)?$/i,
    /^\/status\/.+$/,
    /^\/schedule-appointment\/.+$/,
  ]
  const shouldDisplayNavDrawer = !routesNotDisplayingNavDrawer.some((route) =>
    route.test(pathname),
  )

  function refetchUser() {
    if (
      user.challengeName === 'NEW_PASSWORD_REQUIRED' &&
      !(user as any)?.signInUserSession?.idToken
    ) {
      return
    }
    const email =
      (user as any)?.signInUserSession?.idToken?.payload?.email ?? ''

    fetchData<IUser>(getUserPermissions(email)).then((userInfo: IUser) => {
      setUserData(userInfo)
      setUserRole(userInfo.role || ({} as IRole))

      LogRocket.identify(userInfo.objectId, {
        name: userInfo.name,
        email: userInfo.email,

        role: userInfo.role?.name || 'No Role',
      })
    })
  }

  useEffect(() => {
    //fetch user role & save to local storage
    if (user) {
      refetchUser()
    }
  }, [user])

  // Hacky way to redirect sales users to the sales page.
  // There is a better way to do this, but it requires a lot of changes
  // and is not worth it at the time the tr sales page was given to
  // retail sales reps
  useEffect(() => {
    if (userRole.name === 'Sales') {
      navigate('/tr-sales')
    }
  }, [userRole])

  return (
    <UserContext.Provider value={{ user: userData, refetchUser }}>
      <VanContextProvider>
        <div className={styles.baseContainerWrapper}>
          {shouldDisplayNavDrawer && <NavDrawer links={links}></NavDrawer>}
          <Routes>
            <Route
              path={'/jmullet-tools'}
              element={
                <RequireAuth>
                  <JmulletTools />
                </RequireAuth>
              }
            />
            <Route
              path={'/schedule-appointment/:id'}
              element={<CustomerSchedulerMain mode={MODES.CUSTOMER} />}
            />
            <Route
              path={'/reschedule-appointment/:id'}
              element={
                <CustomerSchedulerMain mode={MODES.CUSTOMER_RESCHEDULE} />
              }
            />
            {hasPermission({
              role: { permissions: userRole.permissions || [] },
            } as IUser).allow(
              PERMISSIONS.NAV_BAR.CATEGORY,
              PERMISSIONS.NAV_BAR.ENTRIES.VIEW_QUEUES.NAME,
            ) && (
              <>
                <Route
                  path='/queues/reschedule'
                  element={
                    <RequireAuth>
                      <QueuesMain queueType={'reschedule'} />
                    </RequireAuth>
                  }
                >
                  <Route
                    path=':id'
                    element={<ModalContent />}
                  />
                </Route>
                <Route
                  path='/queues/tire-rack-data'
                  element={
                    <RequireAuth>
                      <QueuesMain queueType={'tire-rack-data'} />
                    </RequireAuth>
                  }
                >
                  <Route
                    path=':id'
                    element={<ModalContent />}
                  />
                </Route>
              </>
            )}

            {links.map((link) => (
              <Route
                path={String(link.to)}
                element={link.element}
                key={link.title}
              >
                {link.nestedRoutes &&
                  link.nestedRoutes.map((nestedRoute, idx) => {
                    // this ensures that the nested routes don't complain about missing keys
                    // without the need to explicitly set a key on each nested route
                    return (
                      <Route
                        {...nestedRoute.props}
                        key={idx}
                      />
                    )
                  })}
              </Route>
            ))}
            <Route
              path={'/login/*'}
              element={<Login />}
            />
            <Route
              path={'/customer-tip-screen/:id'}
              element={<TipsPage />}
            />
            <Route
              path={'/customer-update-cc/:id'}
              element={<CreditCardUpdateMain />}
            />
            <Route
              path={'/status/:id'}
              element={<OrderTracker />}
            />
            {hasPermission({
              role: { permissions: userRole.permissions || [] },
            } as IUser).allow(
              PERMISSIONS.NAV_BAR.CATEGORY,
              PERMISSIONS.NAV_BAR.ENTRIES.VIEW_USER_MANAGEMENT.NAME,
            ) && (
              <Route
                path='/user-management/roles'
                element={
                  <RequireAuth>
                    <UserManagementMain tabType={'roles'} />
                  </RequireAuth>
                }
              >
                <Route
                  path=':id'
                  element={<ModalContent />}
                />
              </Route>
            )}
          </Routes>
        </div>
      </VanContextProvider>
    </UserContext.Provider>
  )
}

export default BaseContainer
