import NavLinkPropsWithElement from '../../global/types/NavLinkPropsWithElement'
import Schedule from '../../schedule/components/main/ScheduleMain'
import WorkOrders from '../../work-orders/components/main/WorkOrdersMain'
import RescheduleQueue from '../../queues/components/main/QueuesMain'
import Cases from '../../cases/components/main/CasesMain'
import Technicians from '../../technicians/components/main/TechniciansMain'
import PromoCodes from '../../promo-codes/components/main/PromoCodesMain'
import Holidays from '../../holidays/components/main/HolidaysMain'
import Locations from '../../locations/components/main/LocationsMain'
import Reports from '../../reports/components/main/ReportsMain'
import { RequireAuth } from '../components/auth/RequireAuth'
import UserManagement from '../../user-management/main/UserManagementMain'
import ServicesMain from '../../services/main/ServicesMain'
import VansMain from '../../vans/main/VansMain'
import DashboardsMain from '../../dashboards/main/DashboardsMain'
import SmallPartSuppliesMain from '../../small-part-supplies/components/main/SmallPartSuppliesMain'
import { TITLES } from '../constants/nav-bar-links'
import { PERMISSIONS } from '../constants/permissions'
import { Route } from 'react-router-dom'
import ModalContent from '../../work-orders/components/work-order-detail/components/main/ModalContent'
import BiReportsMain from '../../bi-reports/components/main/BiReportsMain'
import TrSalesMain from '../../tr-sales-appointment-booking/main/TrSalesMain'

const linksData: Array<NavLinkPropsWithElement> = [
  {
    to: '/',
    title: TITLES.SCHEDULE,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_SCHEDULE.NAME,
    },
    element: (
      <RequireAuth>
        <Schedule />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={<ModalContent />}
      />,
    ],
  },
  {
    to: '/work-orders',
    title: TITLES.WORK_ORDERS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_WORK_ORDERS.NAME,
    },
    element: (
      <RequireAuth>
        <WorkOrders />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={<ModalContent />}
      />,
    ],
  },
  {
    to: '/queues',
    title: TITLES.QUEUES,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_QUEUES.NAME,
    },
    element: (
      <RequireAuth>
        <RescheduleQueue />
      </RequireAuth>
    ),
  },
  {
    to: '/cases',
    title: TITLES.CASES,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_CASES.NAME,
    },
    element: (
      <RequireAuth>
        <Cases />
      </RequireAuth>
    ),
  },
  {
    to: '/technicians',
    title: TITLES.TECHNICIANS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_TECHNICIANS.NAME,
    },
    element: (
      <RequireAuth>
        <Technicians />
      </RequireAuth>
    ),
  },
  {
    to: '/promo-codes',
    title: TITLES.PROMO_CODES,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_PROMO_CODES.NAME,
    },
    element: (
      <RequireAuth>
        <PromoCodes />
      </RequireAuth>
    ),
  },
  {
    to: '/holidays',
    title: TITLES.HOLIDAYS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_HOLIDAYS.NAME,
    },
    element: (
      <RequireAuth>
        <Holidays />
      </RequireAuth>
    ),
  },
  {
    to: '/vans',
    title: TITLES.VANS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_VANS.NAME,
    },

    element: (
      <RequireAuth>
        <VansMain />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={
          <RequireAuth>
            <VansMain />
          </RequireAuth>
        }
      />,
    ],
  },
  {
    to: '/small-part-supplies',
    title: TITLES.SMALL_PART_INVENTORY,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_SMALL_PART_INVENTORY.NAME,
    },
    element: (
      <RequireAuth>
        <SmallPartSuppliesMain />
      </RequireAuth>
    ),
  },
  {
    to: '/locations',
    title: TITLES.LOCATIONS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_LOCATIONS.NAME,
    },
    element: (
      <RequireAuth>
        <Locations />
      </RequireAuth>
    ),
  },
  {
    to: '/services',
    title: TITLES.SERVICES,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_SERVICES.NAME,
    },
    element: (
      <RequireAuth>
        <ServicesMain />
      </RequireAuth>
    ),
  },
  {
    to: '/tr-sales',
    title: TITLES.TR_SALES,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_TR_SALES.NAME,
    },
    element: (
      <RequireAuth>
        <TrSalesMain />
      </RequireAuth>
    ),
  },
  {
    to: '/reports',
    title: TITLES.CS_REPORTS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_REPORTS.NAME,
    },
    element: (
      <RequireAuth>
        <Reports />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path='work-order/:id'
        element={<ModalContent />}
      />,
    ],
  },
  {
    to: '/dashboards',
    title: TITLES.DASHBOARDS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_DASHBOARDS.NAME,
    },
    element: (
      <RequireAuth>
        <DashboardsMain />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={<ModalContent />}
      />,
    ],
  },
  {
    to: '/bi-reports',
    title: TITLES.BI_REPORTS,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_BI_REPORT_UPLOADS.NAME,
    },
    element: (
      <RequireAuth>
        <BiReportsMain />
      </RequireAuth>
    ),
  },
  {
    to: '/user-management/users',
    title: TITLES.USER_MANAGEMENT,
    permissionInfo: {
      category: PERMISSIONS.NAV_BAR.CATEGORY,
      name: PERMISSIONS.NAV_BAR.ENTRIES.VIEW_USER_MANAGEMENT.NAME,
    },
    element: (
      <RequireAuth>
        <UserManagement tabType='users' />
      </RequireAuth>
    ),
    nestedRoutes: [
      <Route
        path=':id'
        element={<ModalContent />}
      />,
    ],
  },
]

export default linksData
